import { isNil } from 'lodash';
import { ref } from '@vue/composition-api';
export const HIDDEN_GROUP = 'hidden_group'; // 隐藏分组，放在这里的变量不会对外暴露
// 从数据源或者stateSchema中生成propSchema的field
const getFieldFromSourceOrState = (pageletId, source, tag, defaultType) => ({
    id: source.id || '',
    title: source.title || source.id || '',
    from: pageletId,
    type: defaultType || source.type || 'object',
    tag,
    desc: '',
    default: source.default,
});
// 根据页面信息收集当前组件的参数信息
const usePropsSchema = () => {
    const propsSchemas = ref([]);
    const getPropsSchemaFromPageConfig = (pageConfig) => {
        if (!pageConfig)
            return;
        const newPropsSchema = [];
        pageConfig.forEach((pagelet) => {
            const curPagePropSchema = getPropSchemaFromPage(pagelet);
            newPropsSchema.push(curPagePropSchema);
        });
        propsSchemas.value = newPropsSchema;
    };
    const getPropSchemaFromPage = (pagelet) => {
        const curPageProps = [];
        const pageletId = pagelet.id || '';
        const gatherDep = (pageletId, source, tag, defaultType) => {
            curPageProps.push(getFieldFromSourceOrState(pageletId, source, tag, defaultType));
        };
        pagelet.xySources?.forEach((source) => {
            gatherDep(pageletId, source, 'data', 'string');
        });
        pagelet.stateSchema?.fields?.forEach((schema) => {
            gatherDep(pageletId, schema, 'state');
        });
        return {
            id: pagelet.id || '',
            group: pagelet.name || '',
            fields: curPageProps,
        };
    };
    const handleSourceChange = (newSource, pageId, pageName) => {
        const tag = 'data';
        handleChange(newSource, pageId, pageName, tag);
    };
    const handleStateChange = (newState, pageId, pageName) => {
        const tag = 'state';
        handleChange(newState.fields || [], pageId, pageName, tag);
    };
    const handleChange = (newParams = [], pageId, pageName, tag) => {
        const oldParams = listTagFromPage(pageId, tag);
        const buildSourceMap = (sources) => {
            const map = new Map();
            sources.forEach((source) => {
                map.set(source.id, {
                    id: source.id || '',
                    title: source.title,
                    default: source.default,
                    type: source.type,
                });
            });
            return map;
        };
        const oldSourceMap = buildSourceMap(oldParams || []);
        const newSourceMap = buildSourceMap(newParams || []);
        const updateParam = (newSource, oldSource) => {
            const paramConfig = {};
            const keys = ['title', 'default', 'type', 'desc', 'main'];
            keys.forEach((key) => {
                if (!isNil(newSource[key] && newSource[key] !== oldSource[key])) {
                    paramConfig[key] = newSource[key];
                }
            });
            modifyParam(oldSource.id, pageId, tag, {
                paramConfig,
            });
        };
        // 遍历长的那个
        if (oldParams.length >= newParams.length) {
            oldParams.forEach((source) => {
                const newSource = newSourceMap.get(source.id);
                // 删除了变量
                if (!newSource) {
                    deleteParam(source.id, pageId, tag);
                    return;
                }
                updateParam(newSource, source);
            });
            return;
        }
        newParams.forEach((source) => {
            const oldSource = oldSourceMap.get(source.id);
            if (!oldSource) {
                // 增加了变量
                addParam(pageId, {
                    id: source.id || '',
                    title: source.title || '',
                    from: pageId,
                    type: source.type,
                    tag,
                    desc: '',
                    default: source.default,
                }, pageName);
                return;
            }
            updateParam(source, oldSource);
        });
    };
    /**
     * 接下来是定义针对Props的一系列操作 - 查增删改
     */
    // 查找参数
    const findParam = (id, from, tag) => {
        let field = undefined;
        propsSchemas.value.some((group) => {
            field = group.fields.find(field => field.id === id && field.from === from && field.tag === tag);
            return field;
        });
        return field;
    };
    // 获取某个页面中的对应的tag
    const listTagFromPage = (from, tag) => {
        const source = [];
        propsSchemas.value.forEach((group) => {
            group.fields.forEach((field) => {
                if (field.from === from && field.tag === tag) {
                    source.push(field);
                }
            });
        });
        return source;
    };
    // 增加参数
    const addParam = (groupId, field, groupName) => {
        const targetGroup = propsSchemas.value.find(schema => schema.id === groupId);
        if (targetGroup) {
            targetGroup.fields.push(field);
            return;
        }
        propsSchemas.value.push({
            group: groupName || groupId,
            fields: [field],
            id: groupId,
        });
    };
    // 删除参数
    const deleteParam = (id, from, tag) => {
        // 由于分组可能被改变，所以我们无法得知某一个变量被放在何处
        // 只能通过from字段和id字段来遍历整个propsSchema来查找
        propsSchemas.value.some((group) => {
            const targetIndex = group.fields.findIndex(field => field.id === id && field.from === from && field.tag === tag);
            if (targetIndex === -1) {
                return false;
            }
            group.fields.splice(targetIndex, 1);
            return true;
        });
    };
    // 修改参数, 能修改的只有分组，命名，描述和默认值
    const modifyParam = (id, from, tag, modifyOpts) => {
        // 先找到这个字段
        const field = findParam(id, from, tag);
        if (!field)
            throw Error(`试图修改一个不存在的参数${tag}.${id},来自页面${from}`);
        const { groupId, groupName, paramConfig } = modifyOpts;
        // 根据参数，进行修改
        if (groupId) {
            const { id, from } = field;
            deleteParam(id, from, tag);
            addParam(groupId, field, groupName);
        }
        Object.keys(paramConfig).forEach((key) => {
            field[key] = paramConfig[key];
        });
    };
    // 逻辑删除，适用于希望某个参数不对外暴露的场景
    const logicDeleteParam = (id, from, tag) => {
        modifyParam(id, from, tag, { groupId: HIDDEN_GROUP });
    };
    // 页面片删除
    const handlePageletDeleted = (pageletId) => {
        propsSchemas.value.forEach((group) => {
            group.fields.forEach((field) => {
                if (field.from === pageletId) {
                    deleteParam(field.id, field.from, field.tag);
                }
            });
        });
    };
    // 遍历某个页面，将这个页面上没有被收集的参数添加到propsSchema中
    // 这个函数适用于新建了一个页面片的场景（比如数据源自动生成弹窗的场景）
    // 如果是要从整个pageConfig中新建propsSchema，用getPropsSchemaFromPageConfig
    const traversePagelet = (pagelet) => {
        const handler = (source, tag, defaultType) => {
            const exist = findParam(source.id, pagelet.id, tag);
            if (exist)
                return;
            addParam(pagelet.id, getFieldFromSourceOrState(pagelet.id, source, tag, defaultType), pagelet.name);
        };
        pagelet.xySources?.forEach((source) => {
            handler(source, 'data', 'string');
        });
        pagelet.stateSchema?.fields.forEach((field) => {
            handler(field, 'state');
        });
    };
    return {
        propsSchemas,
        getPropsSchemaFromPageConfig,
        handleStateChange,
        handleSourceChange,
        addParam,
        deleteParam,
        modifyParam,
        logicDeleteParam,
        handlePageletDeleted,
        traversePagelet,
    };
};
export default usePropsSchema;
